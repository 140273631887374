/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //preloader
        $(window).on('pageshow', function() { // makes sure the whole site is loaded 
          $('#status').fadeOut(); // will first fade out the loading animation 
          $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website. 
          $('body').delay(350).css({'overflow':'visible'});
        })
        //Preload unfade
        $('a').click(function(){
          var url = $(this).attr('href');

          if($(this).is('[target]') || $(this).hasClass('menu-toggle') || $(this).hasClass('no-toggle')) {
            //prevent unfade
          } else {
            $('#preloader').fadeIn('slow', function(){
              document.location.href = url;
            });
            return false;
          }
        });

        //svg Converter
        $(document).ready(function() {
          $('img[src$=".svg"]').each(function() {
            var $img = jQuery(this);
            var imgURL = $img.attr('src');
            var attributes = $img.prop("attributes");

            $.get(imgURL, function(data) {
              // Get the SVG tag, ignore the rest
              var $svg = jQuery(data).find('svg');

              // Remove any invalid XML tags
              $svg = $svg.removeAttr('xmlns:a');

              // Loop through IMG attributes and apply on SVG
              $.each(attributes, function() {
                $svg.attr(this.name, this.value);
              });

              // Replace IMG with SVG
              $img.replaceWith($svg);
            }, 'xml');
          });

        });

        //menutoggle
        $(document).ready(function () {
          $(".icon").click(function () {
            $("#lmnavbar").fadeToggle(500);
            $(".top-menu").toggleClass("top-animate");
            $(".mid-menu").toggleClass("mid-animate");
            $(".bottom-menu").toggleClass("bottom-animate");
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
         $(document).ready(function() {
          if (!$("body").hasClass("home")) {
          $('#project-page').fullpage({
            //Selectors
              sectionSelector: '.single-project',
              css3: true,
              lockAnchors: true,
            //Navigation
              navigation: false,
              autoScrolling:false,
              fitToSection: false,
              css3: true,
            //Hero
              onLeave: function(index, nextIndex, direction) {
                if(index == 1 && direction == 'down') {
                  //$('.logo').removeClass('negative');
                  //$('.nav-link').removeClass('negative');
                  //$('.menu-bar').removeClass('negative');
                }
                if(index == 2 && direction == 'up') {
                  //$('.logo').addClass('negative');
                  //$('.nav-link').addClass('negative');
                  //$('.menu-bar').addClass('negative');
                }
              },
              afterLoad: function(anchorLink, index) {
                if (index == 1) {
                  $('.nav-link').addClass('negative');
                  $('.menu-bar').addClass('negative');
                  $('.logo').addClass('negative');
                  $(this).find('.project-title').addClass('reveal-text');
                  $(this).find('.project-cat').addClass('reveal-text');
                  $(this).find('.project-link').addClass('reveal-text');
                }
              },
            });
          }
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        //HP vertical line animation
        $(window).on('load', function() {
          $('#line').animate({height: 100}, 1200);
        })

        //SP Fullpage
        $(document).ready(function() {
          $('#hp-projects').fullpage({
            //Selectors
              sectionSelector: '.single-project',
              css3: true,
              scrollingSpeed: 1000,
              easingcss3: 'cubic-bezier(0.895, 0.005, 0.230, 1.005)',
            //Navigation
              navigation: true,
              navigationPosition: 'right',
              showActiveTooltip: true,
            //Hero
              onLeave: function(index, nextIndex, direction) {
                if(index == 1 && direction == 'down') {
                  $('#fp-nav').show();
                  $('.logo g').css('fill', 'white');
                  $('.nav-link').addClass('negative');
                  $('.menu-bar').addClass('negative');
                }
                if(index == 2 && direction == 'up') {
                  $('#fp-nav').hide();
                  $('.logo g').css('fill', 'black');
                  $('.nav-link').removeClass('negative');
                  $('.menu-bar').removeClass('negative');
                }
              },
              afterLoad: function(anchorLink, index) {
                if (index == 1) {
                  $('#fp-nav').hide();
                }
                if (index !== 1) {
                  $(this).find('.project-title').addClass('reveal-text');
                  $(this).find('.project-cat').addClass('reveal-text');
                  $(this).find('.project-link').addClass('reveal-text');
                }
              },
          });
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
